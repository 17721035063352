<template>
  <b-modal
    :id="id"
    size="sm"
    :title="title"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    @ok="handleChange($event)"
    @hidden="resetData"
  >
    <div class="modal-report">
      <validation-observer ref="rule">
        <b-form class="content-form">

          <b-form-group>
            <label for="basicInput">Mật khẩu mới <span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              rules="required|min:6"
              :custom-messages="password"
            >
              <b-form-input
                v-model="dataInput.NewPassword"
                type="password"
                :state="errors.length > 6 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <label for="InputHelp">Nhập lại mật khẩu <span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="repassword"
              rules="required"
              :custom-messages="repassword"
            >
              <b-form-input
                v-model="dataInput.ConfirmNewPassword"
                type="password"
                :state="errors.length > 6 ? false:null"
                @keyup="checkPassword"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="check"
                class="text-danger"
              >{{ message }}</small>

            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
    </div>
  </b-modal>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex'

import {
  BRow, BCol, BFormGroup, BModal, BFormInput, BForm, BFormTextarea, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

import VSelect from 'vue-select'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BModal,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormTextarea,
    BButton,
    DateTimePicker,
    VSelect,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    userId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,
      password: {
        required: 'Vui lòng nhập mật khẩu',
        min: 'Mật khẩu phải hơn 6 kí tự',
      },
      repassword: {
        required: 'Vui lòng nhập lại mật khẩu',
      },
      dataCombobox: [],
      dataInput: {
        userId: '',
        NewPassword: '',
        ConfirmNewPassword: '',
      },
      check: true,
      message: '',
    }
  },
  methods: {

    // Kiểm tra mật khẩu
    checkPassword() {
      if (this.dataInput.NewPassword !== this.dataInput.ConfirmNewPassword && this.dataInput.ConfirmNewPassword.length > 0) {
        this.check = true
        this.message = 'Mật khẩu không khớp'
      } else {
        this.check = false
      }
    },

    handleChange(bvModalEvt) {
      bvModalEvt.preventDefault()
      if (!this.check) {
        this.$refs.rule.validate().then(success => {
          if (success) {
            this.dataInput.userId = this.userId
            this.$emit('changPassword', this.dataInput)
          }
        })
      }
    },

    resetData() {
      this.dataInput = {}
    },
  },

}
</script>

<style lang="scss">
.modal-report {
  .action {
    display: flex;
    justify-content: end;
  }
}
</style>
