<template>
  <div>
    <div class="page-container pb-0 mb-2">
      <filter-component
        @filterDistrictCommune="handleFilter($event)"
      />
    </div>
    <div
      id="users"
      class="page-container-table"
    >

      <!--phần header-->
      <button-all-header
        :contentBtnAdd="'Thêm tài khoản liên kết'"
        :arrayExcel="arrayExcel"
        :showBtnMultiDelete="showBtnMultiDelete"
        @clickDelete="deleteItems"
        @clickDowloadSample="downloadExampleFile"
        @clickExportExcel="downloadExportFile"
        @importFile="importFileExcel($event)"
        @clickAdd="showModalCreate"
        @search="search($event)"
      />

      <vue-good-table
        ref="user-table"
        style-class="vgt-table"
        :columns="columns"
        :rows="dataList || []"
        :select-options="{
          enabled: true,
          vertialAlignTop: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        @on-selected-rows-change="selectRowTable"
      >
        <!-- Slot: Table Row -->
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: số hiệu văn bản-->
          <span v-if="props.column.field === 'code'">
            <b-badge variant="light-primary">
              {{ props.row.code }}
            </b-badge>
          </span>

          <span v-else-if="props.column.field === 'emailConfirmedString'">
            <b-badge :variant="props.row.emailConfirmed ? 'light-success' : 'light-danger' ">
              {{ props.row.emailConfirmedString }}
            </b-badge>
          </span>

          <!-- Column:Chức năng -->
          <span v-else-if="props.column.field === 'ActionFunction'">
            <span
              @click="showModalEdit(props.row.id)"
            >
              <feather-icon
                v-b-tooltip.hover.top="'Chỉnh sửa'"
                icon="EditIcon"
                size="18"
                class="text-body"
              />
            </span>
            <span
              class="ml-2"
              @click="deleteItem(props.row.id)"
            >
              <feather-icon
                v-b-tooltip.hover.top="'Xóa'"
                icon="Trash2Icon"
                size="18"
                class="text-body"
              />
            </span>
            <span>

              <b-dropdown
                variant="link"
                boundary="viewport"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="18"
                    class="text-body"
                  />
                </template>
                <!-- xem chi tiet  -->
                <b-dropdown-item
                  @click="handleActive(props.row.userId, props.row.emailConfirmed)"
                >
                  <feather-icon
                    v-if="!props.row.emailConfirmed"
                    icon="CheckCircleIcon"
                    size="16"
                    class="mr-50"
                  />
                  <feather-icon
                    v-if="props.row.emailConfirmed"
                    icon="XCircleIcon"
                    size="16"
                    class="mr-50"
                  />
                  <span>{{ props.row.emailConfirmed === false ? 'Kích hoạt' : 'Hủy kích hoạt' }}</span>
                </b-dropdown-item>
                <b-dropdown-item @click="handleOpenModal(props.row.userId)">
                  <feather-icon
                    icon="LockIcon"
                    size="16"
                    class="mr-50"
                  />
                  <span>Cấp mật khẩu</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>
        </template>
      </vue-good-table>

      <!-- pagination -->
      <my-pagination
        :totalItems="totalRecord"
        :currentPage="urlQuery.pageNumber"
        @pageClick="pageChange"
      />

      <!-- modal xóa người dùng-->
      <confirm-modal
        :id="confirmModalId"
        :content="modalContent"
        @accept="deleteAction"
      />
      <!-- thêm mới modal  -->
      <modal-create
        :id="modalIdCreate"
        :title="modalType==='add' ?'Thêm tài khoản liên kết' : 'Chỉnh sửa tài khoản liên kết'"
        :dataDetail="dataDetail"
        :type="modalType"
        @accept="handleModal"
      />
      <!-- thêm mới modal  -->

      <!-- modal đổi mật khẩu -->
      <modal-change
        :id="modalIdChange"
        title="Cấp lại mật khẩu"
        :userId="userId"
        @changPassword="handleChangPassword($event)"
      />
    </div>
  </div>
</template>

<script>
import { formatFullName, formatDateToDDMM } from '@core/utils/filter'
import { mapActions, mapGetters } from 'vuex'
import {
  BBadge,
  BMedia,
  BDropdown,
  BDropdownItem,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'

import VueGoodTable from '@/components/table/VueGoodTable.vue'
import ButtonComponent from '@/components/button/ButtonComponent.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import UserAvatar from '@/components/avatar/UserAvatar.vue'
import FilterComponent from './components/FilterComponent.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import Search from '@/components/search/Search.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import ModalCreate from './components/ModalCreate.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'
import ModalChange from './components/ModalChange.vue'
// import dataFake from '../../dataFake'

export default {
  components: {
    VueGoodTable,
    BBadge,
    BMedia,
    MyPagination,
    UserAvatar,
    BDropdown,
    BDropdownItem,
    FeatherIcon,
    Search,
    ButtonComponent,
    ConfirmModal,
    BButton,
    VBTooltip,
    ModalCreate,
    ButtonAllHeader,
    FilterComponent,
    ModalChange,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  filters: {
    formatDateToDDMM,
  },
  data() {
    return {
      arrayExcel: [
        'code',
        'name',
        'account',
        'password',
        'email',
        'address',
        'position',
        'affiliatedUnit',
        'userType',
      ],
      formatFullName,
      columns: [
        {
          label: 'MÃ NHÂN VIÊN',
          field: 'code',
          sortable: false,
        },
        {
          label: 'TÊN NHÂN VIÊN',
          field: 'name',
          sortable: false,
        },
        {
          label: 'TÊN TÀI KHOẢN',
          field: 'account',
          sortable: false,
        },
        {
          label: 'ĐƠN VỊ TRỰC THUỘC',
          field: 'affiliatedUnit',
          sortable: false,
        },
        {
          label: 'TRẠNG THÁI',
          field: 'emailConfirmedString',
          sortable: false,
        },
        {
          label: 'CHỨC NĂNG',
          field: 'ActionFunction',
          sortable: false,
          width: '175px',
        },
      ],
      currentPage: 0,
      urlQuery: {
        affiliatedUnitId: '',
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      modalIdChange: 'modal-id-change',
      confirmModalId: 'confirm-modal-account-linked',
      modalContent: '',
      showBtnMultiDelete: false,
      deleteIds: [],
      isActive: false,
      modalType: '',
      totalRecord: 0,
      dataDetail: {},
      idDetail: '',
      modalIdCreate: 'modal-id-account-linked',
      modalIdEdit: 'modal-id-edit-account-linked',
      dataList: [],
      userId: '',
    }
  },
  created() {
    this.fetchData(this.urlQuery)
  },
  methods: {
    ...mapActions('miningAccount', [
      'downloadExportFile',
      'downloadExampleFile',
      'getApiExcel',
    ]),
    // phân trang
    pageChange(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchData(this.urlQuery)
    },
    // xóa 1 người dùng
    deleteItem(id) {
      this.deleteIds = []
      this.deleteIds.push(id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // click xóa nhiều người dùng
    deleteItems() {
      this.deleteIds = this.$refs['user-table'].selectedRows.map(({ id }) => id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // Xoá người dùng của hệ thống
    async deleteAction() {
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.DELETE_ACCOUNT_LINKED, this.deleteIds).then(() => {
        this.$hideAllPageLoading()
        this.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchData(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$bvToast.toast('Xóa không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // click show modal thêm vs xóa
    showModalCreate() {
      this.modalType = 'add'
      this.$bvModal.show(this.modalIdCreate)
    },
    // click show modal thêm vs xóa
    async showModalEdit(id) {
      this.modalType = 'edit'
      this.idDetail = id
      this.$showAllPageLoading()
      const { data } = await axiosApiInstance.get(`${ConstantsApi.DETAIL_ACCOUNT_LINKED}${this.idDetail}`)
      this.dataDetail = data
      this.$hideAllPageLoading()
      this.$bvModal.show(this.modalIdCreate)
    },

    // Chọn người dùng để thực hiện action
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },

    // thêm
    async handleModal(param) {
      if (this.modalType === 'add') {
        const payload = {
          ...param,
        }
        this.$showAllPageLoading()
        const res = await axiosApiInstance.post(ConstantsApi.CREATE_ACCOUNT_LINKED, payload)
        if (res.status === 200) {
          this.$hideAllPageLoading()
          this.$bvToast.toast('Thêm mới thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
          this.$bvModal.hide(this.modalIdCreate)
          this.fetchData(this.urlQuery)
        } else {
          this.$hideAllPageLoading()
          this.$bvToast.toast('Thêm mới không thành công', {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          })
        }
      } else if (this.modalType === 'edit') {
        const payload = {
          id: this.idDetail,
          ...param,
        }
        this.$showAllPageLoading()
        const res = await axiosApiInstance.put(ConstantsApi.EDIT_ACCOUNT_LINKED, payload)
        if (res.status === 200) {
          this.$hideAllPageLoading()
          this.$bvToast.toast('Chỉnh sửa thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
          this.$bvModal.hide(this.modalIdCreate)
          this.fetchData(this.urlQuery)
        } else {
          this.$hideAllPageLoading()
          this.$bvToast.toast('Chỉnh sửa không thành công', {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          })
        }
      }
    },

    // Fetch data
    async fetchData(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.API_ACCOUNT_LINKED, {
        params: urlQuery,
      })
      this.dataList = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },

    // Lấy danh sách khi tìm kiếm
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.fetchData(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.fetchData(this.urlQuery)
      }
    },

    // Xử lí filter
    handleFilter(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.affiliatedUnitId = val
        this.fetchData(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.affiliatedUnitId = ''
        this.fetchData(this.urlQuery)
      }
    },
    // Thêm dữ liệu bằng file excel
    async importFileExcel(event) {
      if (event) {
        this.getValidata(event)
      }
    },
    async getValidata(dataInput) {
      const model = {
        listExcel: dataInput,
        isValidate: true,
      }
      await this.getApiExcel(model)
      await this.$router.push({ name: 'mining-account-import-file' })
    },

    // xử lí active account
    async handleActive(id, active) {
      if (active) {
        this.$showAllPageLoading()
        await axiosApiInstance.post(`${ConstantsApi.UNACTIVE_ACCOUNT}${id}`)
        this.fetchData(this.urlQuery)
        this.$hideAllPageLoading()
      } else {
        this.$showAllPageLoading()
        await axiosApiInstance.post(`${ConstantsApi.ACTIVE_ACCOUNT}${id}`)
        this.fetchData(this.urlQuery)
        this.$hideAllPageLoading()
      }
    },

    // Mở modal đổi mật khẩu
    handleOpenModal(id) {
      this.userId = id
      this.$bvModal.show(this.modalIdChange)
    },

    // Xử lí đổi mật khẩu
    async handleChangPassword(val) {
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.CHANGE_PASSWORD, val).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Cấp lại mật khẩu thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchData(this.urlQuery)
        this.$bvModal.hide(this.modalIdChange)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Cấp mật khẩu thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },
  },

}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

#users {
  .text-name {
    color: #227ff4;
  }

  .light-code {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
  }

  .border-status {
    border-radius: 4px;
  }

  .button-icon {
    .btn-component {
      width: 38px;
      height: 38px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 11px;
    }
  }

  .button-icon-custom {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
  }

  .search-input {
    width: 400px;
  }

  .button-import {
    .input-file {
      display: none;
    }
  }

  .btn-icon-file {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
    label {
      margin-bottom: unset !important;
    }
  }
}
</style>
