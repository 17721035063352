<template lang="">
  <div>
    <b-modal
      :id="id"
      cancel-variant="outline-primary"
      ok-title="Lưu"
      cancel-title="Hủy bỏ"
      centered
      size="xl"
      no-close-on-backdrop
      :title="title"
      @ok="accept($event)"
      @hidden="resetData"
    >

      <validation-observer ref="rule">

        <b-form>
          <h3 class="pt-1 pb-1">
            <feather-icon
              icon="InfoIcon"
              size="18"
              class="text-body"
            />
            Thông tin
          </h3>
          <b-row>
            <b-col>
              <b-form-group>
                <label>Mã nhân viên<span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="code"
                  rules="required"
                  :custom-messages="code"
                >
                  <b-form-input
                    v-model="dataInput.code"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <label>Tên nhân viên<span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  rules="required"
                  :custom-messages="name"
                >
                  <b-form-input
                    v-model="dataInput.name"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <label>Tên tài khoản<span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="account"
                  rules="required"
                  :custom-messages="account"
                >
                  <b-form-input
                    v-model="dataInput.account"
                    :disabled="type!=='edit' ? false : true"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

          </b-row>
          <b-row
            v-if="type!=='edit'"
          >
            <b-col>
              <b-form-group>
                <label>Mật khẩu<span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="password"
                  rules="required|min:6"
                  :custom-messages="password"
                >
                  <b-form-input
                    v-model="dataInput.password"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="type!=='edit'"
            >
              <b-form-group>
                <label>Nhập lại mật khẩu<span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="repassowrd"
                  rules="required"
                  :custom-messages="repassowrd"
                >
                  <b-form-input
                    v-model="dataInput.rePassword"
                    :state="errors.length > 0 ? false:null"
                    @keyup="checkPassword"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="check"
                    class="text-danger"
                  >{{ message }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col>
            </b-col>
          </b-row>
          <h3 class="pt-1 pb-1">
            <feather-icon
              icon="MapPinIcon"
              size="18"
              class="text-body"
            />
            Địa chỉ
          </h3>
          <b-row>
            <b-col>
              <b-form-group>
                <label for="code">Đơn vị trực thuộc<span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="affiliated"
                  rules="required"
                  :custom-messages="affiliated"
                >
                  <v-select
                    v-model="dataInput.affiliatedUnitId"
                    :reduce="item => item.id"
                    label="name"
                    :options="affiliatedList || []"
                    placeholder="Lựa chọn đơn vị trực thuộc"
                  >
                    <template v-slot:no-options="{ searching }">
                      <template v-if="searching">
                        Không có kết quả.
                      </template>
                      <em
                        v-else
                      >Không có dữ liệu</em>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <label for="code">Kiểu người dùng<span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="typeuse"
                  rules="required"
                  :custom-messages="typeuse"
                >
                  <v-select
                    v-model="dataInput.userTypeId"
                    :reduce="item => item.id"
                    label="name"
                    :options="useType || []"
                    placeholder="Lựa chọn kiểu người dùng"
                  >
                    <template v-slot:no-options="{ searching }">
                      <template v-if="searching">
                        Không có kết quả.
                      </template>
                      <em
                        v-else
                      >Không có dữ liệu</em>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <label>Email<span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required"
                  :custom-messages="email"
                >
                  <b-form-input
                    v-model="dataInput.email"
                    type="email"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

          </b-row>
          <b-row>
            <b-col>
              <b-form-group>
                <label>Chức vụ</label>
                <b-form-input
                  v-model="dataInput.position"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <label>Địa chỉ</label>
                <b-form-input
                  v-model="dataInput.address"
                />
              </b-form-group>
            </b-col>
            <b-col>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>
<script>

import {
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BRow,
  BCol,
  BFormCheckbox,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormCheckbox,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    dataDetail: {
      type: Object,
      default: _ => {},
    },
    type: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      required,
      code: {
        required: 'Mã nhân viên là bắt buộc',
      },
      name: {
        required: 'Tên nhân viên là bắt buộc',
      },
      account: {
        required: 'Tên tài khoản là bắt buộc',
      },
      password: {
        required: 'Mật khẩu là bắt buộc',
        min: 'Mật khẩu phải hơn 6 kí tự',
      },
      repassowrd: {
        required: 'Mật khẩu nhập lại là bắt buộc',
      },
      affiliated: {
        required: 'Đơn vị trực thuộc là bắt buộc',
      },
      typeuse: {
        required: 'Kiểu người dùng là bắt buộc',
      },
      email: {
        required: 'email là bắt buộc',
      },
      dataInput: {
        code: '',
        name: '',
        account: '',
        position: '',
        affiliatedUnitId: '',
        address: '',
        email: '',
        userTypeId: '',
        password: '',
        rePassword: '',
      },
      affiliatedList: [],
      useType: [],
      check: true,
    }
  },
  watch: {
    dataDetail(val) {
      if (val && this.type === 'edit') {
        this.dataInput = {
          code: this.dataDetail.code,
          name: this.dataDetail.name,
          account: this.dataDetail.account,
          position: this.dataDetail.position,
          affiliatedUnitId: this.dataDetail.affiliatedUnitId,
          address: this.dataDetail.address,
          email: this.dataDetail.email,
          userTypeId: this.dataDetail.userTypeId,
          password: this.dataDetail.password,
        }
      }
    },
  },

  created() {
    this.fetchCombobox()
    this.fetchComboboxUseType()
  },

  methods: {

    async fetchCombobox() {
      const { data } = await axiosApiInstance.get(ConstantsApi.COMBOBOX_AFFILIATE)
      this.affiliatedList = data.data
    },
    async fetchComboboxUseType() {
      const { data } = await axiosApiInstance.get(ConstantsApi.COMBOBOX_TYPE)
      this.useType = data.data
    },
    accept(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('accept', this.dataInput)
        }
      })
    },

    // Đặt lại dữ liệu rỗng
    resetData() {
      this.dataInput = {}
    },

    // Kiểm tra mật khẩu
    checkPassword() {
      if (this.dataInput.password !== this.dataInput.rePassword && this.dataInput.rePassword.length > 0) {
        this.check = true
        this.message = 'Mật khẩu không khớp'
      } else {
        this.check = false
      }
    },
  },
}
</script>
<style lang="scss">
  .btn-down {
    display: grid;
    margin-bottom: 15px;
    align-items: end;
  }
</style>
